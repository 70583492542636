<template>
  <ion-page>
    <ion-split-pane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>Bienvenido</ion-list-header>
            <ion-note>{{getUser.email}} - {{getUser.role.name}} </ion-note>
  
            <ion-menu-toggle auto-hide="false"  style="cursor: pointer;">
              
              <ion-item 
                v-for="(p, i) in appPages" 
                :key="i"  
                @click="selectedIndex = i" 
                router-direction="root" 
                :router-link="p.url" 
                lines="none" 
                detail="false" 
                :class="{ 
                  'selected': selectedIndex === p.url,
                  'active' : p.has_permission ,
                  'not-active': !p.has_permission,
                  'hydrated': true
                }">
                <ion-icon 
                  slot="start" 
                  :ios="p.iosIcon" 
                  :md="p.mdIcon"
                >
                </ion-icon>
              
                <ion-label>
                  {{ p.title }}
                </ion-label>
              </ion-item>
              
              <ion-item 
                @click="logout" 
                lines="none" 
                detail="false" 
                class="hydrated" 
              >
                <ion-icon 
                  slot="start"  
                  :ios="exitOutline" 
                  :md="exit"
                >
                </ion-icon>
              
                <ion-label>
                  Cerrar Sesión
                </ion-label>
              </ion-item>
            
            </ion-menu-toggle>
          </ion-list>
  
          <!--<ion-list id="labels-list">
            <ion-list-header>Labels</ion-list-header>
  
            <ion-item v-for="(label, index) in labels" lines="none" :key="index">
              <ion-icon slot="start" :ios="bookmarkOutline" :md="bookmarkSharp"></ion-icon>
              <ion-label>{{ label }}</ion-label>
            </ion-item>
          </ion-list>-->
        </ion-content>
      </ion-menu>

      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-page>
</template>

<script>

import { 
  IonPage, 
  IonContent, 
  IonIcon, 
  IonItem, 
  IonLabel, 
  IonList, 
  IonListHeader, 
  IonMenu, 
  IonMenuToggle, 
  IonNote, 
  IonRouterOutlet, 
  IonSplitPane 
} from '@ionic/vue';
import { 
  defineComponent, 
//  ref 
} from 'vue';
import { useRoute } from 'vue-router';
import { 
  archiveOutline, 
  archiveSharp,
  bookmarkOutline, 
  bookmarkSharp, 
  heartOutline, 
  heartSharp, 
  mailOutline, 
  mailSharp, 
  paperPlaneOutline, 
  paperPlaneSharp,
  peopleOutline,
  peopleCircle,
  peopleCircleOutline, 
  peopleSharp,
  trashOutline, 
  trashSharp, 
  warningOutline, 
  warningSharp,
  personOutline,
  person,
  exitOutline,
  exit,
  personAddOutline,
  personAddSharp,
  desktopOutline,
  desktop,
  medkitOutline,
  medkit
} from 'ionicons/icons';
import { mapGetters } from "vuex";

export default defineComponent({
  name: 'App',
  components: {
    IonPage,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  data(){
    return{
      selectedIndex: null,
      appPages : [
        {
          title: 'Escritorio',
          url: '/escritorio',
          meta : 'escritorio',
          has_permission : false,
          iosIcon: desktopOutline,
          mdIcon: desktop
        },
        {
          title: 'Oficinas',
          url: '/colaboradores' ,
          meta : 'colaboradores',
          has_permission : false,
          iosIcon: peopleOutline,
          mdIcon: peopleSharp
        },
        {
          title: 'Administradores',
          url: '/administradores',
          meta : 'administradores',
          iosIcon: peopleCircleOutline,
          has_permission : false,
          mdIcon: peopleCircle
        },
        {
          title: 'Clientes',
          url: '/clientes',
          meta : 'clientes',
          iosIcon: personOutline,
          has_permission : false,
          mdIcon: person
        },
        {
          title: 'Despacho',
          url: '/sub-administradores',
          meta : 'sub-administradores',
          has_permission : false,
          iosIcon: personOutline,
          mdIcon: person
        },
        {
          title: 'Siniestros',
          url: '/siniestros',
          meta : 'siniestros',
          has_permission : false,
          iosIcon: warningOutline,
          mdIcon: warningSharp
        },
        {
          title: 'Asignar Rol',
          url: '/asignar-rol',
          meta : 'asignar-rol',
          has_permission : false,
          iosIcon: personAddOutline,
          mdIcon: personAddSharp
        },
        {
          title: 'Área de Clinicas',
          url: '/clinicas',
          meta : 'clinicas',
          has_permission : false,
          iosIcon: medkitOutline,
          mdIcon: medkit
        }
      ]
    }
  },
  mounted(){
    //const selectedIndex = ref(0);
    this.selectedIndex = this.$route.path

    /*const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }*/
    
    var permissions = this.getUser.permissions;

    permissions.forEach(permission => {
      this.appPages.forEach(page =>{
        if (permission.key == page.meta) {
          page.has_permission = true
        }
      })
    })
  },
  methods : {
    logout(){
      this.$store.dispatch('unsetAuthUser')
      .then(() => {
        location.href = "/login"
      });
    }
  },
  watch : {
    '$route.path'(){
       this.selectedIndex = this.$route.path
    }
  },
  setup() {
    
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

    const route = useRoute();
    
    return { 
      labels,
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      exitOutline,
      exit,
      isSelected: (url) => url === route.path ? 'selected' : ''
    }
  }
});
</script>

<style type="text/css">
  ion-menu.menu-pane-visible {
  width: 300px !important;
}
</style>
<style scoped>



.active{
  display: block;
}

.not-active{
  display: none;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
